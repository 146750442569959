import { Module } from "vuex";
import { getters } from "@/store/users/getters";
import { actions } from "@/store/users/actions";
import { mutations } from "@/store/users/mutations";
import { UsersState } from "@/store/users/types";
import { RootState } from "@/store/types";

export const state: UsersState = {
  users: [],
  searchedUsers: [],
  loading: false,
};

const namespaced: boolean = true;

export const users: Module<UsersState, RootState> = {
  namespaced,
  state: state,
  getters,
  actions,
  mutations,
};
