import { ActionTree, Store } from "vuex";
import { UsersState } from "@/store/users/types";
import { RootState } from "@/store/types";
import { fetchUsers, searchUsersByEmail } from "@/api/users";

export const actions: ActionTree<UsersState, RootState> = {
  async fetchUsers({ commit }) {
    try {
      commit("loading");
      const response = await fetchUsers();
      const users = [];
      for (let item of response.data.results) {
        users.push({ ...item.user, groups: item.groups });
      }

      commit("setUsers", users);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async searchUsersByEmail({ commit }, email: string) {
    try {
      commit("loading");
      const result = await searchUsersByEmail(email);
      commit("setSearchedUsers", result.data);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  refresh({ dispatch }) {
    dispatch("fetchUsers");
  },

  clearSearchResults({ commit }) {
    commit("clearSearchResults");
  },
};
