import { MutationTree } from "vuex";
import { UsersState } from "@/store/users/types";

export const mutations: MutationTree<UsersState> = {
  setUsers(state, payload) {
    state.users = payload;
    state.loading = false;
  },

  setSearchedUsers(state, payload) {
    state.searchedUsers = payload;
    state.loading = false;
  },

  clearSearchResults(state) {
    state.searchedUsers = [];
  },

  loading(state) {
    state.loading = true;
  },
};
